import React from "react"
import Logo from "../../images/Pacefluent_Logo.svg"
import { makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
  },
  inputContainer: {
    flexGrow: 2,
  },
  logo: {
    maxWidth: "35vw",
  },
  headerMessage: {
    padding: 15,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("xs")]: {
      textAlign: "right",
    },
  },
}))

const Header = () => {
  const classes = useStyles()
  return (
    <header
      className={classes.root}
      role="banner"
      aria-labelledby="header-logo"
    >
      <Link to="/">
        <img
          id="header-logo"
          className={classes.logo}
          src={Logo}
          width={170}
          height={54}
          alt="Paceology"
        />
      </Link>
      <div className={classes.headerMessage}>
        <Typography
          color="primary"
          component="span"
          variant="h1"
          style={{ fontSize: 21 }}
        >
          Website Speed Optimization
        </Typography>
      </div>
    </header>
  )
}
export default Header
